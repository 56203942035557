/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountMyOrders {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    @include mobile {
        grid-gap: 28px;
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }
    }

    .searchOrders {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .Field {
            margin: 10px 10px 10px 0;

            input {
                width: 250px;
                border: 2px solid var(--button-border);
            }
        }

        button.btn {
            line-height: 34px;
            margin: 0 7px;
            font-size: 15px;
            padding: 0 15px;
            
            &[disabled] {
                pointer-events: none;
                opacity: .5;   
            }
        }
    }
}
