/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Affiliate {
    &-Tab {
        .react-tabs__tab-list {
            border-bottom: 1px solid var(--expandable-content-divider-color);
            margin-block-end: 25px;
        }

        .react-tabs__tab {
            padding: 10px 20px;
            border-radius: 0;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid var(--expandable-content-divider-color);
            border-left: 0;
            margin: 0;

            &:first-child {
                border-left: 1px solid var(--expandable-content-divider-color);
            }

            &--selected {
                background-color: var(--primary-accent-color);
                color: var(--btn-text-color);
                border-color: var(--primary-accent-color);
            }
        }
    }
}
