/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.CreateAccount {
    .ContentWrapper {
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block-start: 20px;
        }

        >div {
            flex: 1;
            margin: 0 10px;
        }

        button,
        input {
            min-width: 250px;

            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
            }
        }

        p {
            margin-block-end: 20px;
        }
    }

    .Loader {
        margin: 0;
    }

    &-Promo {
        max-width: 360px;
        border-radius: 5px;

        img {
            width: auto;
            border-radius: 5px;
        }

        &-Text {
            position: absolute;
            top: 60px;
            left: 50%;
            margin-left: -150px;
            z-index: 1;
            text-align: center;
            width: 300px;
            text-transform: uppercase;

            &-Heading {
                font-size: 40px;
                line-height: 0.9;
                color: #fff;
                font-family: intro, sans-serif;
                text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

                span {
                    margin-block-start: 3px;
                    display: block;
                    font-size: 26px;
                    line-height: 1;
                    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
                }
            }

            &-Content {
                margin-block-start: 20px;
                font-size: 16px;
                line-height: 1.1;
                color: #fff;
                font-family: regular-bold, sans-serif;
            }
        }
    }

    .PrivacyConsentCheckboxes .Button[disabled] {
        background: var(--text-red-color);
        opacity: .5;
    }
}
