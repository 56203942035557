/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable number-max-precision */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-tab-list-item-color: #404040;
}

.MyAccountTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        button {
            &::after {
                content: none;
            }
        }
    }

    &_isActive,
    &:hover {
        .MyAccountTabListItem-Button {
            font-weight: 700;
        }
    }

    &_isActive {
        @include mobile {
            display: none;
        }
    }

    &-Button {
        padding: 10px 0;
        min-width: 100%;
        min-height: 38px;
        text-align: start;
        text-shadow: var(--text-shadow);
        letter-spacing: .03ex;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            padding: 10px 24px;
            min-width: 250px;
        }

        .ChevronIcon {
            width: 14px;
            height: 14px;
            display: inline-block;
        }
    }
}
