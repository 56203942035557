/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.MyAffiliate {
    &-MyBalance {
        display: flex;
        margin-block-end: 25px;
    }

    &-Item {
        background: #eee;
        padding: 10px 15px;
        margin-inline-end: 10px;
    }

    &-list {
        .action {
            &:hover {
                color: var(--text-red-color);
            }
            // &.Repeat {
            //     pointer-events: none;
            // }
        }

        @media (max-width:1100px) {
            thead {
                display: none;
            }

            tr td {
                display: block;
                padding: 10px 0;

                &::before {
                    padding-inline-end: 10px;
                    content: attr(data-th) ": ";
                    display: inline-block;
                    color: #000000;
                    font-weight: 700;
                }
            }
        }
    }

    &-Request {
        margin-block-end: 40px;

        label {
            display: block;
            margin-block-end: 10px;
        }

        .send_que {
            display: flex;

            button.btn {
                order: unset;
                background-color: var(--text-red-color);
                color: var(--btn-text-color);
                border: 2px solid rgba(0, 0, 0, 0.2);
                width: auto;
                height: auto;
                font-size: 16px;
                border-radius: 999px;

                &:hover {
                    background-color: var(--text-red-hover-color);
                }
            }
        }

        input[type="number"] {
            width: 100%;
            max-width: 200px;
            margin-inline-end: 20px;
            pointer-events: unset;
            text-align: unset;
            font-weight: normal;
            border: 2px solid var(--button-border);
            order: unset;
        }

        button {
            line-height: 35px;
        }
    }
}
