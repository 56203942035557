/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.MyAccount {
    div.Loader {
        position: absolute;
    }

    .MyAccountDashboard, .MyAccountAddressTable, .MyAccountMyWishlist-ActionBar {
        button ~ button {
            margin-left: 12px;
        }
    }

    .MyAccount-TabContent {
        margin: 0;

        .Pagination {
            display: flex;

            &-ListItem {
                margin: 0 4px;
            }
        }
    }
}

.MyAccountAddressPopup {
    form.Form.FieldForm {
        .FieldForm-Fields {
            .Field {
                &.Field_type_Po_box {
                    display: none;
                }

                &.Field_type_ {
                    display: none;

                    &VatSucc,
                    &VatError {
                        position: absolute;
                        bottom: -26px;
                        left: 51%;

                        @include mobile {
                            inset-inline: 0;
                        }

                        label {
                            font-size: 13px;
                            background: #e5efe5;
                            color: #006400;
                            padding: 4px 8px;
                        }

                        input {
                            display: none;
                        }
                    }

                    &VatError {
                        label {
                            background: rgba(190, 14, 14, 0.09);
                            color: var(--primary-error-color);
                        }
                    }        
                }
            }
        }
                    
        button.Button.MyAccount-Button {
            margin-top: 25px;
        }
    }

    @include desktop {
        form.Form.FieldForm {
            max-width: 500px;
            margin: 0 auto;
            padding-block-end: 30px;

            .FieldForm-Fields {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .Field {
                    width: 49%;

                    &.Field_type_checkbox, &.Field_type_textarea {
                        width: 100%;
                    }

                    &.Field_type_,
                    &.Field_type_Po_box {
                        display: none;
                    }
                }
            }
        }
    }
}
