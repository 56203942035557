/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.MyAffiliate {
    &-list {
        margin-block-end: 50px;

        .coupon_code {
            input[type="text"] {
                border: none;
                padding: 0;
                width: 125px;
            }

            button {
                font-size: 16px;

                .fa-check-circle {
                    color: green;
                }
            }
        }
    }

    &-message {
        border-top: 1px solid var(--expandable-content-divider-color);
        padding-block-start: 15px;
        font-style: italic;
    }

    &-PromoData {
        @media (max-width:1100px) {
            thead {
                display: none;
            }

            tr td {
                display: block;
                padding: 10px 0;

                &::before {
                    padding-inline-end: 10px;
                    content: attr(data-th) ": ";
                    display: inline-block;
                    color: #000000;
                    font-weight: 700;
                }
            }
        }
    }

    &-ReferalLink {
        margin-block-end: 20px;

        input[type="text"] {
            width: 100%;
        }

        .ref_des {
            margin-block-end: 5px;
        }

        button.btn {
            margin-left: auto;

            &[disabled] {
                pointer-events: none;
                opacity: .5;
            }
        }

        .ref_data {
            display: flex;
            align-items: flex-start;
            margin-block-start: 10px;

            textarea {
                border: none;
                padding: 0;
                margin-inline-end: 15px;
            }
        }
    }
}
