/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable no-missing-end-of-source-newline */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccount {
    @include mobile {
        max-width: 490px;
        margin-inline: auto;
        width: 100%;
    }

    >section {
        width: 100%;

        @include desktop {
            max-width: 1180px;
            margin-inline: auto;
        }

        .MyAccount-Wrapper {
            padding-inline: 10px;
            max-width: 100%;

            @include mobile {
                padding-inline: 5px;
            }
        }

        @media (min-width: 1600px) {
            max-width: 1415px;
        }
    }
    // &Overlay-Checkbox {
    //     right: 5px;
    // }

    table {
        width: 100%;
        table-layout: auto;
        text-align: left;
    }

    li::before {
        display: none;
    }

    @include desktop {
        .MyAccountNewsletterSubscription-Button {
            position: static;
            display: block;
            margin-block-start: 5px;
        }

        &Overlay {
            inset-block-start: calc(var(--header-nav-height) - 7px);

            &-PersonalFields {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                grid-gap: 12px;
                width: 100%;

                .Field {
                    flex: 1;

                    input {
                        width: 100%;
                    }
                }
            }
        }
    }

    &OrderPopup-Content {
        padding-block-end: 10px;
    }

    .MyAccount {
        &-TabContent {
            padding: 1rem;

            @include desktop {
                padding: 1.5rem;
            }
        }

        &-Heading {
            font-size: 20px;
            padding-block-end: 10px;
            margin: 0 0 1.5rem;
            color: #666669;
            text-transform: uppercase;
            border-bottom: 1px solid #e2e2e2;

            @include desktop {
                font-size: 28px;
            }

            @media (max-width: 992px) {
                font-size: 22px;
            }
        }

        &Dashboard {
            @include desktop {
                grid-gap: 3rem;
            }
        }

        &Overlay-Checkbox {
            inset-inline: 0;
        }
    }

    .MyAccountNewsletterSubscription .Field > label {
        font-weight: normal;
    }

    &OrderPopup div h4 {
        font-size: 1.3rem;
    }

    &Dashboard {
        grid-gap: 2rem;
        position: static;
    }

    .AddToCart {
        min-width: unset;
    }

    .MyAccountTabList {
        &-ExpandableContentButton::before,
        &-ExpandableContentButton::after {
            right: 2rem;
        }
        // &-ExpandableContentContent_isContentExpanded {
        //     padding: 0 1rem 1rem;
        // }

        &Item-Button::after {
            right: 1rem;
        }

        @include desktop {
            &-ExpandableContentContent {
                padding: 0 10px;
                margin-block-start: 0;
            }
        }
    }

    @include mobile {
        .MyAccount-Wrapper {
            padding: 0;
        }
    }

    &TabListItem {
        button {
            font-size: 16px;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: var(--text-light-black-color);

            @include desktop {
                padding: 10px 3px 10px 8px;

                &::after {
                    right: 1rem;
                }
            }
        }

        &_isActive button {
            color: var(--text-red-color);

            .ChevronIcon {
                fill: var(--text-red-color)
            }
        }
    }

    .FrontrunnerLoader-Wrapper {
        position: fixed;
    }

    &TabList ul {
        list-style: none;
    }

    .ExpandableContent {
        &-Heading {
            font-family: regular-bold, sans-serif;
            font-size: 19px;
            color: var(--text-dark-gray-color);
        }

        &-Button {
            padding: .5rem 1rem;

            @include desktop {
                display: none;
            }
        }
    }

    p:not([class*=Heading]) {
        font-size: 16px;
        font-family: 'regular-book', sans-serif;
        color: var(--text-dark-gray-color);
    }

    @include desktop {
        .MyAccountOverlay-Action {
            &_state_signIn {
                display: flex;
                flex-wrap: wrap;

                form.Form,.MyAccountOverlay-Additional {
                    width: 49%;
                }

                .MyAccountOverlay-Additional {
                    margin-left: auto;
                }
            }

            .MyAccountOverlay-Heading {
                width: 100%;
            }
        }
    }

    &+.Breadcrumbs {
        display: none;
    }

    .PasswordChangePage-Wrapper {
        padding-inline: 0;

        .MyAccount-Buttons {
            margin-block-start: .5rem;
        }
    }

    .MyAccountOverlay-Additional_state_createAccount {
        display: block;
    }
}

.MyAccountOverlay-Additional section {
    display: block;
}

.MyAccountOverlay, .MyAccountAddressPopup, .MyAccountCustomerPopup, .Checkout-Wrapper {
    background: var(--overlay-background);
    padding: 14px;

    &-Action,
    .Popup-Content {
        @include mobile {
            max-width: 490px;
            margin-inline: auto;
            min-width: unset;
            width: 100%;
        }
    }

    .CheckoutPayment-Button {
        padding-inline-end: 10px;
    }

    .Popup-Content {
        padding: 0;
        position: static;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     top: 0;
        //     bottom: 0;
        // }
    }

    .FrontrunnerLoader-Wrapper {
        position: fixed;
    }

    &-Legend {
        margin-block-end: 20px;
        
        &.create-info {
            margin-block-end: 50px;
        }

        // .MyAccountOverlay-Checkbox {
        //     margin-top: 0;
        // }

        legend {
            font-size: 18px;
            font-family: regular-bold, sans-serif;
            color: var(--text-gray-color);
            padding: 10px 0 6px;

            svg {
                font-size: 22px;
                margin-inline-end: 3px;
            }
        }
    }

    .MyAccountOverlay-Additional_state_signIn {
        margin-block-start: 55px;

        h4 {
            font-size: 15px;
            color: var(--text-dark-gray-color);
        }
    }

    .MyAccountOverlay-Checkbox {
        margin-block-end: 0;
    }

    .ExplorerRewardsAgreement {
        display: flex;
        align-items: left;

        .Field_type_checkbox {
            margin: 0 !important;
        }

        &-Terms {
            flex: 1;
        }

        a {
            color: var(--text-dark-gray-color);
            text-decoration: underline;

            &:hover {
                color: var(--text-red-color);
            }
        }
    }

    .Button {
        border-radius: 999px !important;

        &:hover,
        &:focus {
            border-radius: 999px !important;
        }
    }

    .Button:not(.Button_likeLink, .Button_isHollow) {
        margin-block: .5rem 15px;
        background-color: var(--text-red-color);
        line-height: 34px;
        font-size: 16px !important;

        &[disabled] {
            background-color: var(--text-red-color);
        }
    }

    .Button_likeLink {
        font-size: 14px !important;
        margin-block-start: 5px;
        background: transparent;
        line-height: unset;
    }

    input {
        width: 100%;

        &[type='checkbox'],
        &[type='radio'] {
            width: auto;
        }
    }

    &-Heading {
        color: var(--text-gray-color);
        font-size: 24px;
        line-height: 1.3;
        text-transform: uppercase;
        border-bottom: 1px solid #efefef;
        margin-block-end: 10px !important;
        font-family: regular-bold, sans-serif;
        display: block;
        padding: 0 0 5px;
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 2rem;
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: start;
            padding: 1rem 2rem;
            font-size: 1.2rem;

            &:hover {
                background-color: var(--color-cart-light);
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }

            @include desktop {
                padding: .5rem;
            }
        }

        @include desktop {
            padding: .5rem;
        }
    }

    &-Action form,
    &-Additional,
    form.FieldForm {
        padding: 0;

        .title {
            font-size: 24px;
            font-family: regular-bold, sans-serif;
            color: var(--text-dark-gray-color);
            margin-block-end: 25px;
            border-bottom: 1px dotted var(--border-color);
            line-height: 1.42;
            padding: 0 0 20px;

            &.login {
                background: url(../../images/login.png) no-repeat;
                background-size: 20px;
                padding-left: 28px;
            }
        }

        ul,li {
            padding: 0;
            list-style: none;
        }

        li {
            margin-block-end: 10px;
            font-size: 16px;
            color: var(--text-light-black-color);
            line-height: 1.3;

            &::before {
                display: none;
            }

            svg.fa-2x {
                font-size: 18px;
                color: #a8be18;
                margin-inline-end: 12px;
            }
        }
    }

    .Field label {
        font-size: 14px;
        line-height: 18px;
        color: var(--text-light-black-color);
        font-weight: 400;
        vertical-align: middle;
        align-items: center;
    }

    ul.FieldSelect-Options {
        background: #fff;
        border-width: 0;

        li.FieldSelect-Option {
            font-size: 14px;
            margin: 0;
            padding: 3px 8px;
            text-transform: unset;
        }

        &_isExpanded {
            border-width: 0 1px 1px;
        }
    }

    ul.FieldSelect-Options
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=tel],
    select,
    textarea {
        border: 2px solid rgba(0,0,0,.2);
        height: 40px;
        padding: 0 9px;
        font-size: 14px;
    }

    textarea {
        max-width: 100%;
        height: auto;
        padding: 10px;
        width: 100%;
    }

    &-Action {
        display: grid;
        height: 100%;
        grid-template-rows: max-content 1fr;

        &_state {
            &_createAccount {
                h4 {
                    padding-inline-end: 1rem;
                    display: inline;
                }

                h3 {
                    margin-block-end: 1rem;
                }

                .Field {
                    margin-block-start: .7rem;
                }

                @include mobile {
                    padding-block-start: 1rem;
                }
            }

            &_forgotPassword {
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }

                @include desktop {
                    width: 180px;
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 1.15rem;
            font-weight: bold;
            margin-block-end: .5rem;
        }

        h3 {
            margin-block-start: 0;
        }

        @include desktop {
            padding: 1rem 1rem 1rem;
        }
    }

    &-Additional {
        section {
            padding-block-start: 20px;

            &:first-of-type {
                padding-block-start: 0;
            }
        }

        &_state {
            &_createAccount {
                padding-block-start: 3rem;

                h4 {
                    margin: 0 0 5px;
                    display: block;
                    padding-inline-end: 0;
                    font-size: 18px;
                    font-family: regular-bold, sans-serif;
                    color: var(--text-dark-gray-color);
                }

                button {
                    width: 100%;
                    margin-block-start: 10px !important;
                }
            }

            &_signIn {
                section {
                    flex-direction: column;
                }
            }

            &_forgotPassword {
                padding-block-start: 2.8rem;
                text-align: center;

                section {
                    button {
                        width: initial;
                        margin-block-start: 0;
                        padding-left: 5px;
                        font-weight: 600;

                        &.Button.Button_likeLink {
                            text-align: center;
                        }
                    }

                    h4 {
                        font-size: 15px;
                        color: var(--text-dark-gray-color);
                    }

                    &:last-of-type {
                        padding-block-start: 2rem;
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-block-start: 4.3rem;

                h4 {
                    font-size: 1rem;
                    line-height: 1.5;
                    font-weight: normal;
                    letter-spacing: normal;
                    padding-block-end: 1.4rem;
                }
            }
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Button {
        &_likeLink {
            font-size: .95rem;
            margin-block-start: 1.2rem;
        }
    }

    &-Checkbox {
        > label:last-of-type {
            padding: 0;

            &::after {
                left: .1rem;
                top: .4rem;
                width: .15rem;
                height: .15rem;
            }
        }

        > label:first-of-type {
            margin-left: 0;
        }

        input {
            position: relative;
            width: 20px;
            height: 20px;
            inset-inline-start: 20px;
        }
    }

    .login-back {
        .Button_likeLink {
            font-size: 12px;
            font-weight: normal;
            margin-block-start: 28px;

            &::before {
                content: "«";
                padding-inline-end: 4px;
            }
        }
    }

    #PaymentForm .rccs {
        margin-block: 20px;
    }
}

.MyAccountOverlay {
    &-Promo {
        display: none;
        position: relative;

        @include mobile {
            margin-block-start: 20px;
            padding-block-start: 30px;
            display: block;
        }

        &-Image {
            width: 100%;
            display: block;
            border-radius: 5px;
        }

        &-Text {
            position: absolute;
            top: -30px;
            left: 5px;
            z-index: 1;
            max-width: 100%;
            text-align: center;

            &-Heading {
                color: var(--text-red-color);
                font-family: intro, sans-serif;
                font-size: 27px;
                line-height: 1;

                span {
                    font-size: 18px;
                    display: block;
                }

                @include mobile {
                    font-size: 20px;

                    span {
                        font-size: 16px;
                        margin-block-end: 6px;
                        display: block;
                    }
                }
            }

            &-Content {
                font-family: regular-bold, sans-serif;
                font-size: 13px;
                text-transform: uppercase;
            }
        }
    }

    &-Buttons {
        margin-block-start: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            width: auto;
        }

        @include mobile {
            flex-direction: column;
            gap: 10px;
            margin-block-start: 20px;

            button {
                width: 100%;
            }
        }
    }

    .PrivacyConsentCheckboxes {
        margin-block-start: 0;

        &-Consent {
            padding: 0;
            display: flex;
            font-size: 12px;
            align-items: flex-start;
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }
}

.Overlay.Overlay_isVisible.MyAccountOverlay_isVisible {
    background: #fff;

    .CreateAccount-Actions {
        flex-direction: column;
        text-align: center;

        button:first-child {
            margin-block-end: 20px;
        }

        button span {
            text-decoration: underline;

            &:hover {
                color: var(--text-red-color)
            }
        }
    }
}

.yotpoLoyalty {
    .MyAccountOverlay-Legend {
        display: flex;
    }
    
    .Field.MyAccountOverlay-Checkbox {
        margin-top: 0;
    }
}