/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.LoginAccount, .CreateAccount, .ForgotPassword {
    form, &-CreateAccountWrapper {
        .title {
            font-size: 24px;
            font-family: regular-bold, sans-serif;
            color: var(--text-dark-gray-color);
            margin-block-end: 25px;
            border-bottom: 1px dotted var(--border-color);
            line-height: 1.42;
            padding: 0 0 20px;

            &.login {
                background: url(../../images/login.png) no-repeat;
                background-size: 20px;
                padding-left: 28px;
            }
        }

        label {
            font-size: 14px !important;
            line-height: 18px;
            color: var(--text-light-black-color);
            font-weight: 400;
        }

        .ExplorerRewardsAgreement {
            display: flex;
            flex-direction: row;
            
            .Field {
                margin-top: 0;
            }

            a {
                color: var(--text-dark-gray-color);
                text-decoration: underline;

                &:hover {
                    color: var(--text-red-color);
                }
            }
        }

        .Button_likeLink {
            color: var(--text-dark-gray-color);
            margin-block-start: 10px;
        }

        .Button_isHollow {
            margin-block-start: 10px;
        }
    }
}

.LoginAccount {
    .LoginAccount-InnerWrapper {
        position: static;
    }

    @include mobile {
        .LoginAccount-CreateAccount {
            text-align: center;
            margin-block-start: 45px;
        }

        button.Button.Button_likeLink {
            margin-block-start: 5px;

            &:hover {
                color: var(--text-red-color) !important;
            }
        }
    }
}

.MyAccountOverlay-Additional_state_signIn {
    .Button.Button_likeLink {
        width: 100%;
        color: var(--text-red-color) !important;
    }

    .MyAccountOverlay-Heading {
        text-align: left;
    }
}

.MyAccountOverlay-Additional_state_createAccount {
    .Button.Button_likeLink {
        color: var(--text-red-color) !important;
    }

    .MyAccountOverlay-Heading {
        text-align: left;
    }
}
