/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

.MyAccount {
    @include desktop {
        .MyAccountAddressBook-Button {
            inset-block-start: -65px;
            right: 0;
        }
    }
}

.CheckoutAddressForm {
    .FieldSelect-Select {
        pointer-events: unset;
    }

    ul.FieldSelect-Options {
        display: none;
    }
}

div .PrivacyConsentCheckboxes {
    margin-block-end: 10px;

    &-Consents {
        margin: 0;
    }

    &-BoxLabel {
        margin-left: 2px;
        font-size: 14px;

        p:last-of-type {
            margin-block-end: 0;
        }
    }
}
