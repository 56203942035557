/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

.CreateAccount {
    &-Actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        a {
            color: var(--text-dark-gray-color);

            &:hover {
                color: var(--text-red-color);
            }

            span {
                text-decoration: underline;
            }
        }
    }

    &-InnerWrapper {
        button {
            margin-block-start: 0 !important;
        }

        input {
            flex: 1;
            min-width: 100% !important;
            width: 100%;
        }
    }
}

a.termLink {
    color: inherit;
    text-decoration: underline;
    
    &:hover {
        color: var(--link-color);
    }
}
