/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderPopup {
    font-size: 14px;

    &_isVisible {
        overflow-y: scroll !important;
    }

    &-Address,
    &-Products {
        margin-block-end: 14px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            margin-block-end: 28px;
            overflow-x: unset !important;
        }
    }

    .Popup-Heading {
        font-size: 28px;
        line-height: 1;
        color: #666669;
        text-transform: uppercase;
        font-family: "Regular-Bold", sans-serif;
    }

    &-Products {
        width: 100%;
        margin-block-start: 12px;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;

            thead {
                display: none;
            }

            tr td {
                display: block;

                &::before {
                    padding-inline-end: 10px;
                    content: attr(data-th) ": ";
                    display: inline-block;
                    color: #000000;
                    font-weight: 700;
                }

                .MyAccountOrderPopup-Thumbnail,
                span {
                    display: block;
                }
            }
        }

        td,
        th {
            vertical-align: middle;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Content {
        min-height: 200px;
    }

    h4 {
        font-size: 14px;

        @include mobile {
            font-size: 17px;
        }
    }

    dt {
        font-weight: bold;
    }

    dl {
        display: grid;
        margin-block-start: 12px;
        grid-template-columns: 1fr 3fr;
        grid-gap: 6px;

        @include mobile {
            margin-block-start: 14px;
            grid-gap: 7px;
            margin-block-end: 28px;
            grid-template-columns: 1fr 2fr;
        }
    }

    td,
    th {
        min-width: 0;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
    }

    .order-details-items {
        border: 1px solid #cccccc;
        padding: 5px 18px 15px;
        margin-block-end: 14px;

        @include mobile {
            padding: 5px 5px 5px;
        }
    }

    .order-tab {
        border: 1px solid #ccc;
        border-bottom: none;
        display: inline-block;
        vertical-align: bottom;
        margin-block-start: 20px;

        & > div {
            display: inline-block;
            padding: 10px 20px;
            border-right: 1px solid #ccc;
            background: #f6f6f6;
            border-bottom: 1px solid #ccc;
            margin-block-end: -1px;
            z-index: 1;
            cursor: pointer;

            @include mobile {
                padding: 10px 15px;
            }

            &:last-child {
                border-right: none;
            }

            &.tab_active {
                background: #fff;
                border-bottom: 1px solid #fff;
                cursor: unset;
            }

            &:hover {
                background: #fff;
            }
        }
    }

    .InvoiceDetails {
        margin-block-start: 15px;

        a.action.print {
            color: #444;
            font-size: 12px;
        }

        .actions-toolbar {
            margin-block-end: 8px;
        }

        .invoice-title strong {
            font-size: 24px;
            font-weight: normal;
            color: #333;
        }

        tr.invoice-Row {
            border-bottom: 1px solid #cccccc;
            padding: 0 0 10px;
            display: block;
            margin-block-end: 18px;
        }

        .invoice-title {
            display: flex;
            align-items: center;

            .action.print {
                margin-left: 32px;
            }

            @include mobile {
                display: block;

                .action.print {
                    margin: 10px 0 0;
                }
            }
        }

        .action.print {
            cursor: pointer;

            &:hover {
                color: var(--text-red-color);
            }
        }
    }

    &-OrderWrapper {
        text-align: end;

        dl {
            grid-template-columns: 4fr 1fr;

            @include mobile {
                grid-template-columns: 2fr 1fr;
            }
        }
    }

    .order-details-view {
        background: #fdefd6;
        padding: 10px;

        h4 {
            font-family: "Regular-Bold", sans-serif;
            text-transform: uppercase;
            color: #444;
            font-size: 18px;
        }

        .MyAccountOrderPopup-ShippingWrapper {
            margin-block-start: 20px;
        }

        .MyAccountOrderPopup-Address {
            border: 1px solid #e2e2e2;
            background: #fff;
        }
    }
}
